import { FC, ReactNode } from 'react';
import { InputLabel, SelectChangeEvent } from '@mui/material';
import { DropdownFormControl, InvestorSelectControl } from '../../styles';

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

interface IInvestorDropdownBaseProps {
	id: string;
	value: string | string[];
	onChange: (event: SelectChangeEvent<unknown>) => void;
	children: ReactNode;
	multiple?: boolean;
	displayEmpty?: boolean;
	title?: string;
	renderValue?: (selected: unknown) => ReactNode;
	disabled?: boolean;
	isInvestorViewPage?: boolean;
}

export const InvestorDropdownBase: FC<IInvestorDropdownBaseProps> = ({
	id,
	value,
	onChange,
	children,
	displayEmpty = false,
	title,
	renderValue,
	disabled,
	isInvestorViewPage,
}) => {
	return (
		<DropdownFormControl
			variant="outlined"
			size="small"
			style={{ margin: 5, width: '300px' }}
			title={title}
		>
			<InputLabel id={id} style={{ color: '#F1F2F9' }} shrink={true}>
				Investor
			</InputLabel>
			<InvestorSelectControl
				labelId={id}
				id={id}
				value={value}
				onChange={onChange}
				multiple={false}
				displayEmpty={displayEmpty}
				MenuProps={MenuProps}
				renderValue={renderValue}
				disabled={disabled}
				label={'Investor'}
				isInvestorViewPage={isInvestorViewPage}
			>
				{children}
			</InvestorSelectControl>
		</DropdownFormControl>
	);
};
