import { FC, useEffect, useState } from 'react';
import printJS from 'print-js-safari';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import CardsTable from 'common/components/CardsTable/CardsTable';
import { DocumentFilters } from './DocumentFilters';
import { Document } from '../../app/types/document.types';
import {
	DocumentTableKeys,
	DocumentTables,
	IDocumentToDelete,
	TDocumentsListProps,
	documentTableKeys,
} from './types';
import { DeleteDocumentModal } from './DeleteDocumentsModal';
import {
	IDocumentResponse,
	useDeleteDocumentMutation,
	useLazyGetFundDocumentPdfQuery,
	useLazyGetInvestorDocumentPdfQuery,
} from 'api/redux/services/documentApi';
import { useDispatch, useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import {
	selectNavbarMobile,
	selectNavbarTablet,
} from 'api/redux/NavBarStateReducer';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Grid, Pagination, Snackbar } from '@mui/material';
import {
	specificDocumentUrl,
	downloadFile,
	urlForWaterMarkedDoc,
	printWatermarkedDoc,
} from './utils';
import { fileName } from './utils';
import {
	documentsState,
	onSetCurrentDocument,
	onSetCurrentPage,
	onSetSortStrategy,
} from 'api/redux/DocumentsReducer';
import { IAscDesc } from 'api/redux/types';
import {
	useLazyGetOrganizationQuery,
	useLazyGetWatermarkDocTypesQuery,
} from 'api/redux/services/organizations.service';
import { DocumentType } from 'app/types/documentTypes.types';
import { ESortSource } from 'common/components/CardsTable/types';
import { styled } from '@mui/system';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';
import { NoDocuments } from './NoDocuments';
import { ERoutes } from '../../ERoutes';
import { documentColumns } from './DocumentColumns';
import { NotifyDocumentModal } from './NotifyDocumentModal';
import { BulkDownloadButton } from './BulkDownloadDocuments';

const RootGrid = styled(Grid)(() => ({
	width: '100%',
	height: '100%',
	paddingRight: 5,
	paddingLeft: 5,
	paddingTop: 5,
}));

const TableContainer = styled(Box)({
	position: 'relative',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
});

export const DocumentsList: FC<TDocumentsListProps> = ({
	onRefresh,
	pages,
}) => {
	const { user } = useGrants();
	const dispatch = useDispatch();
	const grants = useSelector(selectDropdown);
	const tablet = useSelector(selectNavbarTablet);
	const mobile = useSelector(selectNavbarMobile);
	const navigate = useNavigate();
	const { currentFund, currentSponsor, currentInvestor } = grants.grants;
	const [deleteDocument] = useDeleteDocumentMutation();
	const [getInvestorDocPdf] = useLazyGetInvestorDocumentPdfQuery();
	const [getFundDocPdf] = useLazyGetFundDocumentPdfQuery();
	const [documentToDelete, setDocumentToDelete] = useState<
		IDocumentToDelete | undefined
	>(undefined);
	const [documentToNotify, setDocumentToNotify] = useState<
		Document | undefined
	>(undefined);
	const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
	const [notifyOpen, setNotifyOpen] = useState<boolean>(false);
	const { allDocs, selectedDocTypeIds, currentPage } =
		useSelector(documentsState);
	const [showDocUrlCopied, setShowDocUrlCopied] = useState<boolean>(false);
	const [docName, setDocName] = useState<string>('');
	const [docToDownload, setDocToDownload] = useState<Document | undefined>(
		undefined,
	);
	const [docToPrint, setDocToPrint] = useState<Document | undefined>(undefined);
	const [documents, setDocuments] = useState<IDocumentResponse[]>([]);
	const [fetchOrganization, sponsor] = useLazyGetOrganizationQuery();
	const [fetchWatermarkDocTypes, watermarkDocTypes] =
		useLazyGetWatermarkDocTypesQuery();
	const [allowedWatermarkDocTypes, setAllowedWatermarkDocTypes] = useState<
		DocumentType[]
	>([]);
	const allowedDocTypeForWatermark = (document: Document) => {
		return allowedWatermarkDocTypes
			.map((dt) => dt.id)
			.includes(document.documentTypeId);
	};

	useEffect(() => {
		setDocuments(
			allDocs.filter((doc: IDocumentResponse) =>
				selectedDocTypeIds.includes(doc.documentTypeId),
			),
		);
	}, [allDocs, selectedDocTypeIds]);

	useEffect(() => {
		if (currentSponsor.id === 0) return;

		fetchOrganization(currentSponsor.id);
		fetchWatermarkDocTypes(currentSponsor.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSponsor]);

	useEffect(() => {
		if (!watermarkDocTypes.data) return;

		setAllowedWatermarkDocTypes(watermarkDocTypes.data);
	}, [watermarkDocTypes.data]);

	const fetchPDFDoc = async (document: Document) => {
		const level: DocumentTableKeys =
			documentTableKeys[document.table as DocumentTables];

		if (level === DocumentTableKeys.FT) {
			return await getFundDocPdf(document.id);
		}

		return await getInvestorDocPdf(document.id);
	};

	const onUrlCopy = (doc: Document) => {
		navigator.clipboard.writeText(
			specificDocumentUrl({
				document: doc,
				withBase: true,
				tableKey: documentTableKeys[doc.table as DocumentTableKeys],
				investorId: currentInvestor.id,
			}),
		);
		setShowDocUrlCopied(true);
	};

	const onPrint = (doc: Document) => {
		setDocToPrint(doc);
	};

	const onDownload = (doc: Document) => {
		setDocToDownload(doc);
		setDocName(fileName(doc));
	};

	const onDelete = (doc: Document) => {
		handleDelete(doc);
	};

	const onNotify = (doc: Document) => {
		setNotifyOpen(true);
		setDocumentToNotify(doc);
	};

	const handleDelete = (document: Document) => {
		setDocumentToDelete({
			id: document.id,
			table: document.table as DocumentTables,
		});
		setDeleteOpen(true);
	};

	const handleDeleteConfirm = async () => {
		if (!documentToDelete) return;

		await deleteDocument({
			documentId: documentToDelete.id,
			documentTable: documentToDelete.table,
		});
		onRefresh();
		setDeleteOpen(false);
	};

	const handleSort = (column: string, ascdesc: IAscDesc) => {
		const sortableColumns: string[] = ['type', 'period', 'createdAt'];

		if (!sortableColumns.includes(column)) return;

		dispatch(onSetSortStrategy({ column, ascdesc }));
	};

	const redirectToDocumentView = (document: Document) => {
		dispatch(onSetCurrentDocument(document));
		navigate(ERoutes.DocumentView);
	};

	const initiateDocumentDownload = (document: Document) => {
		setDocToDownload(document);
		const filename = fileName(document);
		setDocName(filename);
	};

	const columns = documentColumns({
		mobile,
		fundName: currentFund.name,
		onDelete,
		onRedirect: redirectToDocumentView,
		onDownload,
		onPrint,
		onUrlCopy,
		onNotify,
	});

	/** Executed everytime a file is downloaded */
	useEffect(() => {
		if (!docToDownload) return;

		fetchPDFDoc(docToDownload).then(async (response) => {
			const pdfBlob = response.data;
			if (pdfBlob === undefined) return;

			let fileUrl = URL.createObjectURL(pdfBlob);

			if (
				sponsor.data?.watermarkPdf &&
				allowedDocTypeForWatermark(docToDownload)
			) {
				fileUrl = await urlForWaterMarkedDoc(fileUrl, user);
			}

			downloadFile({ fileUrl, fileName: docName });
			setDocToDownload(undefined);
			setDocName('');
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sponsor.data, docToDownload]);

	useEffect(() => {
		if (!docToPrint) return;

		fetchPDFDoc(docToPrint).then((response) => {
			const pdfBlob = response.data;
			if (pdfBlob === undefined) return;

			const pdfDocUrl = URL.createObjectURL(pdfBlob);

			if (
				!sponsor.data?.watermarkPdf ||
				!allowedDocTypeForWatermark(docToPrint)
			) {
				printJS({ printable: pdfDocUrl });
				return;
			}

			if (pdfDocUrl) printWatermarkedDoc(pdfDocUrl, user);
			setDocToPrint(undefined);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sponsor.data, docToPrint]);

	return (
		<MainSidebarContainer>
			<Grid
				container
				direction="column"
				sx={{
					width: '100%',
					height: '100%',
					padding: `${tablet ? '10px' : '5px 5px 5px 55px'}`,
				}}
			>
				<RootGrid container spacing={0.5} justifyContent="center" wrap="nowrap">
					<Grid item md={10} lg={10} sx={{ height: '100%' }}>
						<TableContainer>
							<Box
								sx={{
									height: pages > 1 ? '80vh' : '90vh',
									width: '100%',
									marginLeft: 'auto',
									marginRight: 'auto',
									overflow: 'auto',
								}}
							>
								<Snackbar
									open={showDocUrlCopied}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'center',
									}}
									autoHideDuration={3000}
									onClose={() => setShowDocUrlCopied(false)}
								>
									<Alert
										onClose={() => setShowDocUrlCopied(false)}
										severity="success"
										sx={{ width: '100%' }}
									>
										Document URL copied!
									</Alert>
								</Snackbar>
								<BulkDownloadButton />
								<CardsTable
									columns={columns}
									data={documents}
									onSort={handleSort}
									onRowClick={(row) => {
										if (!mobile) return;
										initiateDocumentDownload(row.original);
									}}
									sortSource={ESortSource.DOCUMENTS}
									clickableRow={false}
								/>
								<NoDocuments documents={documents} />
							</Box>

							{pages > 1 ? (
								<Box
									sx={{
										width: '100%',
										textAlign: 'center',
									}}
								>
									<Pagination
										count={pages}
										variant="outlined"
										page={currentPage + 1}
										onChange={(
											_event: React.ChangeEvent<unknown>,
											value: number,
										) => dispatch(onSetCurrentPage(value - 1))}
										sx={{
											'&.MuiPagination-root': {
												backgroundColor: 'unset !important',
												justifyContent: 'center',
												display: 'flex',
												boxShadow: 'none',
												fontFamily:
													'IBMPlexSansRegular, IBMPlexSansBold, IBMPlexSansLight',
												fontSize: 13,
											},
										}}
									/>
								</Box>
							) : (
								<></>
							)}
						</TableContainer>
					</Grid>
					<Grid item lg={2} sx={{ height: '100%' }}>
						<DocumentFilters />
					</Grid>
				</RootGrid>
				<DeleteDocumentModal
					open={deleteOpen}
					handleSubmit={handleDeleteConfirm}
					handleClose={() => setDeleteOpen(false)}
				/>
				<NotifyDocumentModal
					document={documentToNotify}
					open={notifyOpen}
					handleClose={() => setNotifyOpen(false)}
				/>
			</Grid>
		</MainSidebarContainer>
	);
};
