import { FC } from 'react';
import { allowedMultipleDropdownView } from '../Helpers/utils';
import { useLocation } from 'react-router-dom';
import { MultipleInvestorDropdown } from './MultipleInvestorDropdown';
import { SingleInvestorDropdown } from './SingleInvestorDropdown';

export const InvestorDropdown: FC = () => {
	const { pathname } = useLocation();

	const isMultipleAllowed = allowedMultipleDropdownView(pathname);
	if (isMultipleAllowed) {
		return <MultipleInvestorDropdown />;
	}

	return <SingleInvestorDropdown />;
};
