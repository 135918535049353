import { FC, useCallback, useMemo } from 'react';
import { MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import { onChangeInvestors, selectDropdown } from 'api/redux/DropdownReducer';
import { useDispatch, useSelector } from 'react-redux';
import { InvestorDropdownBase } from './InvestorDropdownBase';
import { matchPath, useLocation } from 'react-router-dom';
import { ERoutes } from 'ERoutes';
import { SelectInvestorsButton } from '../SelectInvestorsButton';

export const MultipleInvestorDropdown: FC = () => {
	const dispatch = useDispatch();
	const { grants, showAllUsers } = useSelector(selectDropdown);
	const { currentInvestor, currentInvestors, availableInvestors } = grants;
	const { pathname } = useLocation();
	const isInvestorViewPage = !!matchPath(pathname, ERoutes.InvestorsView);

	const currentInvestorIds = useMemo(
		() => currentInvestors.map((investor) => investor.id.toString()),
		[currentInvestors],
	);

	const handleInvestorChange = useCallback(
		(event: SelectChangeEvent<unknown>) => {
			const selectedValues = Array.isArray(event.target.value)
				? event.target.value
				: [event.target.value];

			if (selectedValues.includes('all')) {
				dispatch(onChangeInvestors(availableInvestors));
				return;
			}

			const selectedInvestors = availableInvestors.filter((investor) =>
				selectedValues.includes(investor.id.toString()),
			);

			dispatch(onChangeInvestors(selectedInvestors));
		},
		[availableInvestors, currentInvestorIds, dispatch],
	);

	const renderValue = useCallback(
		(selected: unknown) => {
			const selectedValues = selected as string[];

			if (
				selectedValues.length === availableInvestors.length &&
				availableInvestors.length > 1
			) {
				return 'All Investors';
			}

			return availableInvestors
				.filter((investor) => selectedValues.includes(investor.id.toString()))
				.map((investor) => investor.name)
				.join(', ');
		},
		[availableInvestors],
	);

	const menuItems = useMemo(() => {
		return [
			<MenuItem key="all" value="all">
				<Typography component={'em'} variant="body1">
					Select All
				</Typography>
			</MenuItem>,
			...availableInvestors.map((investor) => (
				<MenuItem key={investor.id} value={investor.id.toString()}>
					{investor.name}
				</MenuItem>
			)),
		];
	}, [availableInvestors, currentInvestorIds.length]);

	if (isInvestorViewPage && showAllUsers) return <SelectInvestorsButton />;

	return (
		<InvestorDropdownBase
			id="investor-selector-multi"
			value={currentInvestorIds.length ? currentInvestorIds : []}
			onChange={handleInvestorChange}
			multiple
			displayEmpty
			renderValue={renderValue}
			title={currentInvestor.name}
		>
			{menuItems}
		</InvestorDropdownBase>
	);
};
