import { FC } from 'react';
import { Button, styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { onShowAllUsers, selectDropdown } from 'api/redux/DropdownReducer';

const ButtonBox = styled(Button)({
	color: 'white',
	border: '1px solid white',
	width: '150px',
	height: '35px',
	fontSize: '12px',
	padding: '8px 14px',
	'&:hover': {
		border: '1px solid white',
		fontWeight: 'bold',
	},
	textTransform: 'none',
});

export const SelectInvestorsButton: FC = () => {
	const dispatch = useDispatch();
	const { showAllUsers } = useSelector(selectDropdown);

	return (
		<ButtonBox
			variant="outlined"
			size="small"
			sx={{
				margin: '5px',
				'&.MuiButton-outlined': {
					backgroundColor: showAllUsers ? '#FFFFFFF' : 'transparent',
					border: showAllUsers ? '2px solid white' : '1px solid white',
				},
			}}
			onClick={() => dispatch(onShowAllUsers(false))}
		>
			Select Investor
		</ButtonBox>
	);
};
