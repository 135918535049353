import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { STORAGE_KEYS } from 'api/redux/constants/StorageKeys';
import { ERoutes } from 'ERoutes';

/**
 * A component that handles token refresh operations when API requests fail due to token errors.
 *
 * Rendered at the /refresh-token route, this component attempts to obtain a new access token
 * when the API returns a 401 response with errorType='token_verification_error'. Upon
 * successful token refresh, it redirects the user back to their original route.
 *
 * The flow is triggered by the dynamicBaseQuery when it detects a token verification error
 * in the API response. The original route is preserved in sessionStorage before redirection
 * to this component.
 *
 * @route /refresh-token
 * @see dynamicBaseQuery
 */
const RefreshToken: React.FC = () => {
	const { getAccessTokenSilently } = useAuth0();
	const navigate = useNavigate();

	useEffect(() => {
		const refreshToken = async () => {
			try {
				const token = await getAccessTokenSilently();
				sessionStorage.setItem(STORAGE_KEYS.TOKEN.COOKIE, token);

				const redirectUrl = sessionStorage.getItem(
					STORAGE_KEYS.REDIRECT_URL.COOKIE,
				);
				sessionStorage.removeItem(STORAGE_KEYS.REDIRECT_URL.COOKIE);

				if (redirectUrl && !redirectUrl.includes(ERoutes.RefreshToken)) {
					window.location.href = redirectUrl;
				} else {
					navigate(ERoutes.Dashboard);
				}
			} catch (error) {
				console.error('Failed to refresh token:', error);
				navigate(ERoutes.Logout);
			}
		};

		refreshToken();
	}, []);

	return <LoadingSpinner />;
};

export default RefreshToken;
