import { emptyInvestor, GrantState, GrantUpdates } from 'api/redux/types';
import { IFundGrant, IInvestorGrant, ISponsorGrant } from '@services/userApi';
import { DropDownOrderValues } from 'api/redux/types';

export const sortByName = <T extends { name: string }>(
	collection: T[],
): T[] => {
	const sortedCollection = [...collection];
	return sortedCollection.sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const getFirstItemOrEmpty = <T>(items: T[], emptyItem: T): T => {
	return items.length ? items[0] : emptyItem;
};

export const handleMultipleFundChange = (
	selectedFunds: IFundGrant[],
	grants: GrantState['grants'],
) => {
	const allInvestorsWereSelected = allInvestorsAreSelected(grants);

	if (selectedFunds.length === 0) {
		const allInvestors = getAllAvailableInvestors(grants.currentSponsor);
		return {
			currentFunds: [],
			availableInvestors: allInvestors,
			currentInvestors: [],
		};
	}

	const allInvestorsFromFunds = selectedFunds.reduce(
		(acc: IInvestorGrant[], fund) => {
			fund.investors?.forEach((investor) => {
				if (!acc.find((inv) => inv.id === investor.id)) {
					acc.push(investor);
				}
			});
			return acc;
		},
		[],
	);

	const sortedInvestors = sortByName(allInvestorsFromFunds);

	const currentInvestorInNewFund = selectedFunds.some((f) =>
		f.investors.some((investor) =>
			grants.currentInvestors.map((i) => i.id).includes(investor.id),
		),
	);

	const getCurrentInvestors = () => {
		if (grants.firstDropdown !== DropDownOrderValues.FUND) {
			return grants.currentInvestors;
		}

		if (allInvestorsWereSelected) return sortedInvestors;

		return currentInvestorInNewFund ? grants.currentInvestors : sortedInvestors;
	};

	return {
		...grants,
		currentFunds: selectedFunds,
		availableInvestors: sortedInvestors,
		currentInvestors: getCurrentInvestors(),
	};
};

export const switchFirstDropdownToFund = (grants: GrantState['grants']) => {
	const availableFunds = getAvailableFundsForFundOrder(grants);
	const availableInvestors =
		grants.currentFunds.length > 0
			? handleMultipleFundChange(grants.currentFunds, grants).availableInvestors
			: getAvailableInvestorsForFundOrder(grants);
	const currentInv = setCurrentInvestor(grants);

	return {
		availableFunds,
		currentInvestor: currentInv,
		availableInvestors,
		currentInvestors: [currentInv],
		firstDropdown: DropDownOrderValues.FUND,
	};
};

export const switchFirstDropdownToInvestor = (grants: GrantState['grants']) => {
	const allAvailableInvestors = getAllAvailableInvestors(grants.currentSponsor);
	const sortedInvestors = sortByName(allAvailableInvestors);

	const availableFunds = getAvailableFundsForInvestorOrder(
		grants.currentSponsor,
		grants.currentInvestor.id,
	);

	return {
		availableInvestors: sortedInvestors,
		availableFunds: availableFunds,
		currentFund: grants.currentFund, // Preserve current fund
		currentInvestor: grants.currentInvestor,
		currentInvestors: grants.currentInvestors,
		firstDropdown: DropDownOrderValues.INVESTOR,
	};
};

export const getAllAvailableInvestors = (
	sponsor: ISponsorGrant,
): IInvestorGrant[] => {
	const investors = sponsor.funds.reduce((acc: IInvestorGrant[], fund) => {
		fund.investors?.forEach((investor) => {
			if (!acc.find((inv) => inv.id === investor.id)) {
				acc.push(investor);
			}
		});
		return acc;
	}, []);

	return sortByName(investors);
};

export const getInitialSponsorState = (
	selectedSponsor: ISponsorGrant,
	firstDropdown: DropDownOrderValues,
) => {
	const allAvailableInvestors = getAllAvailableInvestors(selectedSponsor);
	const sortedFunds = sortByName(selectedSponsor.funds);
	const [firstFund] = selectedSponsor.funds;
	const [firstInvestor] = firstFund.investors;

	if (firstDropdown === DropDownOrderValues.FUND) {
		return {
			currentSponsor: selectedSponsor,
			currentFund: firstFund,
			currentFunds: [firstFund],
			currentInvestor: firstInvestor,
			currentInvestors: [firstInvestor],
			allAvailableInvestors: sortByName(allAvailableInvestors),
			availableFunds: sortedFunds,
			availableInvestors: sortByName(firstFund.investors),
		};
	}

	const filteredAndSortedFunds = sortedFunds.filter((f) =>
		f.investors.map((i) => i.id).includes(firstInvestor.id),
	);

	return {
		currentSponsor: selectedSponsor,
		currentFund: firstFund,
		currentFunds: [firstFund],
		currentInvestor: firstInvestor,
		currentInvestors: [firstInvestor],
		allAvailableInvestors: sortByName(allAvailableInvestors),
		availableFunds: filteredAndSortedFunds,
		availableInvestors: sortByName(allAvailableInvestors),
	};
};

export const getAvailableFundsForFundOrder = (grants: GrantState['grants']) => {
	return sortByName(grants.currentSponsor.funds);
};

export const getAvailableInvestorsForFundOrder = (
	grants: GrantState['grants'],
) => {
	return sortByName(grants.currentFund.investors);
};

export const setCurrentInvestor = (grants: GrantState['grants']) => {
	const currentInvestor = grants.currentFund.investors.find(
		(investor) => investor.id === grants.currentInvestor.id,
	);
	return (
		currentInvestor ||
		getFirstItemOrEmpty(grants.currentFund.investors, grants.currentInvestor)
	);
};

export const getAvailableFundsForInvestorOrder = (
	currentSponsor: ISponsorGrant,
	currentInvestorId: number,
): IFundGrant[] => {
	const investorFunds = currentSponsor.funds.filter((fund) =>
		fund.investors.some((investor) => investor.id === currentInvestorId),
	);
	return sortByName(investorFunds);
};

export const setCurrentFund = (
	currentFundId: number,
	availableFunds: IFundGrant[],
	defaultFund: IFundGrant,
): IFundGrant => {
	const currentFund = availableFunds.find((fund) => fund.id === currentFundId);
	return currentFund || getFirstItemOrEmpty(availableFunds, defaultFund);
};

export const handleMultipleInvestorChange = (
	selectedInvestors: IInvestorGrant[],
	grants: GrantState['grants'],
): GrantUpdates => {
	const allFundsWereSelected = allFundsAreSelected(grants);
	const allFunds = sortByName(grants.currentSponsor.funds);
	let filteredFunds: IFundGrant[] = [];
	const resolvedSelectedInvestors =
		selectedInvestors.length === 0
			? [grants.availableInvestors[0]]
			: selectedInvestors;

	const currentFundAvailableForInvestors = grants.currentFunds.every((fund) =>
		fund.investors
			.map((i) => i.id)
			.every((id) =>
				resolvedSelectedInvestors.map((inv) => inv.id).includes(id),
			),
	);

	if (grants.firstDropdown === DropDownOrderValues.INVESTOR) {
		filteredFunds = currentFundAvailableForInvestors
			? grants.currentFunds
			: grants.currentSponsor.funds.filter((fund) =>
					resolvedSelectedInvestors.some((selectedInvestor) =>
						fund.investors.some(
							(fundInvestor) => fundInvestor.id === selectedInvestor.id,
						),
					),
			  );
	}

	const getAvailableFunds = () => {
		if (grants.firstDropdown === DropDownOrderValues.FUND) return allFunds;

		return filteredFunds;
	};

	const getCurrentFunds = () => {
		if (grants.firstDropdown === DropDownOrderValues.FUND) {
			return grants.currentFunds;
		}

		return allFundsWereSelected ? filteredFunds : [filteredFunds[0]];
	};

	return {
		currentInvestors: resolvedSelectedInvestors,
		currentInvestor: resolvedSelectedInvestors[0] || emptyInvestor,
		availableFunds: getAvailableFunds(),
		currentFunds: getCurrentFunds(),
	};
};

const allInvestorsAreSelected = (grants: GrantState['grants']): boolean => {
	return (
		grants.availableInvestors.length === grants.currentInvestors.length &&
		grants.availableInvestors.length > 1 &&
		grants.availableInvestors
			.map((af) => af.id)
			.every((fId) => grants.currentInvestors.map((cf) => cf.id).includes(fId))
	);
};

const allFundsAreSelected = (grants: GrantState['grants']): boolean => {
	return (
		grants.availableFunds.length === grants.currentFunds.length &&
		grants.availableFunds.length > 1 &&
		grants.availableFunds
			.map((af) => af.id)
			.every((fId) => grants.currentFunds.map((cf) => cf.id).includes(fId))
	);
};
