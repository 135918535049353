import { ReactElement, useEffect, useState } from 'react';
import { Home } from '../Home';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { useAuth0 } from '@auth0/auth0-react';
import { saveToken } from './utils';
import { Alert } from '@mui/material';
import { STORAGE_KEYS } from 'api/redux/constants/StorageKeys';
import { getCookie } from 'common/helpers/Cookies';
import { useCookies } from 'react-cookie';
import { ERoutes } from 'ERoutes';

type AuthWrapperProps = {
	children: ReactElement<unknown>;
};

const AuthWrapper = ({ children }: AuthWrapperProps): JSX.Element => {
	const { isAuthenticated, isLoading, error, getAccessTokenSilently, logout } =
		useAuth0();
	const [success, setSuccess] = useState<boolean>(false);
	const [, , removeCookies] = useCookies([
		STORAGE_KEYS.FUND.COOKIE,
		STORAGE_KEYS.SPONSOR.COOKIE,
		STORAGE_KEYS.INVESTOR.COOKIE,
		STORAGE_KEYS.SUBDOMAIN.COOKIE,
	]);

	useEffect(() => {
		if (isAuthenticated) {
			getAccessTokenSilently()
				.then((token) => {
					saveToken(token);
					setSuccess(true);
				})
				.catch(() => {
					setSuccess(false);
					handleLogOut();
				});
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (error) {
			console.error(`Auth0 error: ${error.message}`);
		}
	}, [error]);

	const handleLogOut = () => {
		const url = new URL(window.location.origin);
		const subdomain = getCookie('subdomain');
		if (subdomain) {
			url.searchParams.append('subdomain', subdomain);
		}

		logout({ logoutParams: { returnTo: url.toString() } });
		removeCookies(STORAGE_KEYS.FUND.COOKIE, { path: ERoutes.Dashboard });
		removeCookies(STORAGE_KEYS.SPONSOR.COOKIE, { path: ERoutes.Dashboard });
		removeCookies(STORAGE_KEYS.INVESTOR.COOKIE, { path: ERoutes.Dashboard });
		removeCookies(STORAGE_KEYS.SUBDOMAIN.COOKIE, { path: ERoutes.Dashboard });
	};

	if (isLoading) return <LoadingSpinner />;
	if (!isAuthenticated) return <Home />;
	if (error)
		return (
			<Alert severity="error" variant="filled">
				An error occurred while trying to get the access token from Auth0
			</Alert>
		);

	return success ? children : <LoadingSpinner />;
};

export default AuthWrapper;
