import { FC, useCallback } from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { onChangeInvestors, selectDropdown } from 'api/redux/DropdownReducer';
import { useDispatch, useSelector } from 'react-redux';
import { InvestorDropdownBase } from './InvestorDropdownBase';
import { onShowAllUsers } from 'api/redux/DropdownReducer';
import { matchPath, useLocation } from 'react-router-dom';
import { ERoutes } from 'ERoutes';
import { SelectInvestorsButton } from '../SelectInvestorsButton';

export const SingleInvestorDropdown: FC = () => {
	const dispatch = useDispatch();
	const { grants, showAllUsers } = useSelector(selectDropdown);
	const { currentInvestor, currentInvestors, availableInvestors } = grants;
	const { pathname } = useLocation();
	const isInvestorViewPage = !!matchPath(pathname, ERoutes.InvestorsView);

	const currentValues = currentInvestors.map((investor) =>
		investor.id.toString(),
	);

	const handleInvestorChange = useCallback(
		(event: SelectChangeEvent<unknown>) => {
			if (showAllUsers) {
				dispatch(onShowAllUsers(false));
				return;
			}

			const selectedValues = event.target.value as string[];
			const selectedInvestors = availableInvestors.filter((investor) =>
				selectedValues.includes(investor.id.toString()),
			);
			dispatch(onChangeInvestors(selectedInvestors));
		},
		[availableInvestors, currentInvestors, dispatch],
	);

	const renderValue = (selected: unknown) => {
		const selectedValues = selected as string[];

		return availableInvestors
			.filter((investor) => selectedValues.includes(investor.id.toString()))
			.map((investor) => investor.name)
			.join(', ');
	};

	if (isInvestorViewPage && showAllUsers) return <SelectInvestorsButton />;

	return (
		<InvestorDropdownBase
			id="investor-selector-multi"
			value={currentValues}
			onChange={handleInvestorChange}
			multiple
			displayEmpty
			renderValue={renderValue}
			title={currentInvestor.name}
		>
			{availableInvestors.map((investor) => (
				<MenuItem key={investor.id} value={investor.id.toString()}>
					{investor.name}
				</MenuItem>
			))}
		</InvestorDropdownBase>
	);
};
